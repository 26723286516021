import type * as React from 'react'
import styled from 'styled-components'

import {
  Form,
  FormItem,
  FormItemLayout,
  GridCol,
  GridRow,
  H1,
  PrimaryButton,
  PrimaryText,
  SecondaryText,
  TertiaryButton,
  TextInput,
  VSpace,
  useForm,
} from '@settleindex/react2'
import {registerTestIds} from '@settleindex/testids'

import type {CompleteMyRegistrationMutationVariables, UserResponse} from '../graphQLTypes'
import {Logo} from './Logo'
import {PrivacyLink, TosLink} from './legalLinks'
import {userEditFields} from './userEditFields'

import {useUser} from '#lib/user/useUser/useUser.js'

interface Props {
  loading?: boolean
  onSubmit: (data: CompleteMyRegistrationMutationVariables) => void
  user: UserResponse
}

export const CompleteRegistrationForm: React.FC<Props> = ({loading, onSubmit, user}) => {
  const {logout} = useUser()
  const fields = userEditFields({
    required: {phone: !user.isExternal},
    submitLabel: 'Register',
    customLabels: {phone: ''},
    formItemProps: {
      phone: {
        label: 'Mobile Number',
        initialValue: user.phone,
        extra: <SecondaryText>We require your mobile number for security verification only.</SecondaryText>,
      },
    },
  })
  const methods = useForm<CompleteMyRegistrationMutationVariables>({defaultValues: user})
  const onFinish = (values: CompleteMyRegistrationMutationVariables) => {
    const result = {
      ...values,
    }

    onSubmit(result)
  }

  return (
    <Frame>
      <Logo />
      <VSpace space={'xxl'} />
      <H1 data-test-id={registerTestIds.registerHeader}>Complete your registration</H1>
      <PrimaryText style={{maxWidth: 360, textAlign: 'center'}}>
        Please check the details below are correct.
      </PrimaryText>
      <VSpace space={'xl'} />
      <Form methods={methods} name="CompleteRegistrationForm" onSubmit={onFinish}>
        <GridRow>
          <GridCol span={24}>
            <FormItem label="Email" name="email" noStyle>
              <input type={'hidden'} />
            </FormItem>
            <FormItemLayout label="Email">
              <PrimaryText>{user.email}</PrimaryText>
            </FormItemLayout>
          </GridCol>
        </GridRow>
        <GridRow gap={'m'}>
          <GridCol span={12}>
            <FormItem label="First Name" name="firstName">
              <TextInput />
            </FormItem>
          </GridCol>
          <GridCol span={12}>
            <FormItem label="Last Name" name="lastName">
              <TextInput />
            </FormItem>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol span={24}>
            <FormItem label="Company" name="company">
              <TextInput />
            </FormItem>
          </GridCol>
        </GridRow>
        {!user.isExternal ? (
          <GridRow className="pad-on-error">
            <GridCol span={24}>{fields.phone}</GridCol>
          </GridRow>
        ) : (
          <></>
        )}

        <PrimaryButton
          data-test-id={registerTestIds.submitButton}
          htmlType="submit"
          loading={loading}
          style={{width: '100%'}}
        >
          Register
        </PrimaryButton>
      </Form>

      <VSpace space={'l'} />
      <SecondaryText style={{maxWidth: 360, textAlign: 'center'}}>
        By clicking 'register' above, you agree to our <TosLink /> and <PrivacyLink />.
      </SecondaryText>
      <TertiaryButton onClick={logout} style={{marginTop: 20}}>
        Cancel and sign out
      </TertiaryButton>
    </Frame>
  )
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  align-items: center;
  justify-content: center;
`
