import React, {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import type {Right} from '@settleindex/domain'

import {DebugSection} from '../debug/DebugSection'
import {LoadingIndicatorWithNav} from '../page'
import {usePageTitle} from '../pageTitle/usePageTitle'
import {AutoLogout} from '../user/inactivity/AutoLogout'
import {useUser} from '../user/useUser/useUser'
import {paths} from './paths'

const DisputeDashboardContainer = React.lazy(() => import('../dispute/dashboard/DisputeDashboardContainer'))
const NewDisputeFormContainer = React.lazy(() => import('../dispute/form/NewDisputeFormContainer.js'))
const EditDisputeFormContainer = React.lazy(() => import('../dispute/form/EditDisputeFormContainer.js'))
const DisputeListContainer = React.lazy(() => import('../dispute/list/DisputeListContainer'))
const DisputeHistoryContainer = React.lazy(
  () => import('../dispute/history/disputeHistoryPage/DisputeHistoryContainer'),
)
const TeamContainer = React.lazy(() => import('../disputeTeam/teamPage/TeamContainer'))
const UserCreatePageContainer = React.lazy(() => import('../admin/user/create/UserCreatePageContainer'))
const UserEditPageContainer = React.lazy(() => import('../admin/user/edit/UserEditPageContainer'))
const UserListPageContainer = React.lazy(() => import('../admin/user/list/UserListPageContainer'))
const VersionCompareContainer = React.lazy(() => import('../dispute/versionCompare/VersionCompareContainer'))
const VersionContainer = React.lazy(() => import('../version/VersionContainer'))

interface LoggedInRoutingProps {
  rights?: Right[]
}

export const LoggedInRouting: React.FC<LoggedInRoutingProps> = ({rights}) => {
  const {isAdmin} = useUser()
  usePageTitle()

  return (
    <Suspense fallback={<LoadingIndicatorWithNav />}>
      <AutoLogout />
      <Switch>
        <Route component={DisputeListContainer} exact path="/" />
        <Route component={NewDisputeFormContainer} exact path={paths.newDisputePattern} />

        <Route component={EditDisputeFormContainer} exact path={paths.disputeSettingsPattern} />
        <Route component={TeamContainer} exact path={paths.disputeSharingListPattern} />
        <Route component={DisputeDashboardContainer} path={paths.disputePattern} />
        <Route component={VersionCompareContainer} path={paths.versionComparePattern} />
        <Route component={DisputeHistoryContainer} path={paths.disputeMetricsPattern} />
        <Route
          exact
          path={paths.disputeRootPattern}
          render={(props) => <Redirect to={paths.dispute(props.match.params.disputeId as string)} />}
        />

        <Route component={VersionContainer} path={paths.versionPattern} />

        <Route exact path={paths.admin()} render={() => <Redirect to={paths.userAdmin()} />} />
        <Route
          exact
          path={paths.userAdmin()}
          render={(props) => <UserListPageContainer {...props} rights={rights} />}
        />
        <Route
          exact
          path={paths.userAdminCreate()}
          render={(props) => <UserCreatePageContainer {...props} rights={rights} />}
        />
        <Route
          exact
          path={paths.userAdminEditPattern}
          render={(props) => <UserEditPageContainer {...props} rights={rights} />}
        />
        <Route render={() => <Redirect to={paths.home()} />} />
      </Switch>
      {isAdmin && (
        <>
          <DebugSection />
        </>
      )}
    </Suspense>
  )
}
